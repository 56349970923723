import React, { useState, useEffect, useRef } from "react"
import { navigate } from "gatsby"

import { openModal } from "../services/modal"
import Header from "../components/header"
import Footer from "../components/footer"
import Layout from "../components/layout"
import styles from "./watchNow.module.scss"
import IconButton from "../components/iconButton"
import GetStarted from "../components/getStarted"
import Button from "../components/button"
import multisportIcon from "../images/sports/icon/multisport.svg"
import mobileIcon from "../images/common/mobile-phone-iphone.svg"
import globalStyles from "../services/globalStyles.module.scss"
import { graphql, Link } from "gatsby"
import chevronIcon from "../images/common/chevron2.svg"
import chevronIconBlack from "../images/common/chevron3.svg"

import basketballVid from "../videos/sports/Basketball.mp4"
import fieldHockeyVid from "../videos/sports/Field_Hockey.mp4"
import floorballVid from "../videos/sports/Floorball.mp4"
import handballVid from "../videos/sports/Handball.mp4"
import iceHockeyVid from "../videos/sports/Ice_Hockey.mp4"
import multisportVid from "../videos/sports/Multisport.mp4"
import soccerVid from "../videos/sports/Soccer.mp4"
import volleyballVid from "../videos/sports/Volleyball.mp4"
import appVid from "../videos/XPS_App.mp4"

import posterImg from "../images/watch_now_poster.png"

const ProgressBar = () => {
  return (
    <div className={styles.progressRing}>
      <svg width="120" height="120">
        <circle
          stroke="#333"
          stroke-width="4"
          fill="transparent"
          r="30"
          cx="60"
          cy="60"
        />
        <circle
          className={styles.circle}
          stroke="white"
          stroke-width="4"
          fill="transparent"
          r="30"
          cx="60"
          cy="60"
        />
      </svg>
    </div>
  )
}

const WatchNow = ({ data, pageContext }) => {
  const _ = data.texts._
  const sportContent = [
    {
      title: _.customers.all_sports,
      icon: {
        publicURL: multisportIcon,
      },
      video: multisportVid,
    },
    {
      title: _.sports.soccer,
      icon: {
        name: "soccer",
        extension: "svg",
        relativePath: "uploads/soccer.svg",
        publicURL: "/static/6768a5d3580134ffc81709daf939f42e/soccer.svg",
      },
      video: soccerVid,
    },
    {
      title: _.sports.basketball,
      icon: {
        name: "basketball",
        extension: "svg",
        relativePath: "sports/icon/basketball.svg",
        publicURL: "/static/f5e16dc203bd3f27d11e3a748002b6d4/basketball.svg",
      },
      video: basketballVid,
    },
    {
      title: _.sports.handball,
      icon: {
        name: "handball",
        extension: "svg",
        relativePath: "sports/icon/handball.svg",
        publicURL: "/static/5d88e9b00fec1e4c224f4892af1379ec/handball.svg",
      },
      video: handballVid,
    },
    {
      title: _.sports.volleyball,
      icon: {
        name: "volleyball",
        extension: "svg",
        relativePath: "sports/icon/volleyball.svg",
        publicURL: "/static/3882e324dff6c0d698ceed38af736049/volleyball.svg",
      },
      video: volleyballVid,
    },
    {
      title: _.sports.ice_hockey,
      icon: {
        name: "ice_hockey",
        extension: "svg",
        relativePath: "sports/icon/ice_hockey.svg",
        publicURL: "/static/d5b8ed706a918418731e42347b12c0ab/ice_hockey.svg",
      },
      video: iceHockeyVid,
    },
    {
      title: _.sports.floorball,
      icon: {
        name: "floorball",
        extension: "png",
        relativePath: "sports/icon/floorball.png",
        publicURL: "/static/719f1221f37d44fffc473c32bfe0be1e/floorball.png",
      },
      video: floorballVid,
    },
    {
      title: _.sports.field_hockey,
      icon: {
        name: "field_hockey",
        extension: "png",
        relativePath: "sports/icon/field_hockey.png",
        publicURL: "/static/3226cc999155d450692311527f665128/field_hockey.png",
      },
      video: fieldHockeyVid,
    },
    {
      title: _.sports.tennis,
      icon: {
        name: "tennis",
        extension: "png",
        relativePath: "sports/icon/tennis.png",
        publicURL: "/static/a7baf41c584b04c06539ced35e8cf3a8/tennis.png",
      },
      video: multisportVid,
    },
    {
      title: _.watch_now.xps_app,
      icon: {
        name: "xps app",
        publicURL: mobileIcon,
      },
      video: appVid,
    },
  ]

  const languageCode = pageContext?.lang || "en"
  const sportFromUrl = pageContext?.sport || "general"

  const defaultActiveSport =
    sportContent.find((item) => item.icon.name === sportFromUrl)?.title ||
    _.customers.all_sports

  const [activeSport, setActiveSport] = useState(defaultActiveSport)
  const [isTryForFreeDisplayed, setIsTryForFreeDisplayed] = useState(false)
  const [timeoutId, setTimeoutId] = useState(null)
  const [wasModalOpened, setWasModalOpened] = useState(false)
  const [posterClicked, setPosterClicked] = useState(false)
  const [backIconUrl, setBackIconUrl] = useState(chevronIconBlack)

  const videoRef = useRef(null)
  const sportsRef = useRef(null)

  useEffect(() => {
    const indexOfSport = sportContent.findIndex(
      (item) => item.icon.name === sportFromUrl
    )

    if (indexOfSport > 3) {
      setTimeout(
        () => sportsRef.current.scrollBy((indexOfSport - 3) * 200, 0),
        500
      )
    }
  }, [sportFromUrl])

  const displayTryForFree = () => {
    setIsTryForFreeDisplayed(true)
    setTimeoutId(() =>
      setTimeout(() => {
        setActiveSport((prev) => {
          const index = sportContent.findIndex((s) => s.title === prev)
          if (index < 0 || index === sportContent.length - 1) {
            return sportContent[0].title
          } else {
            return sportContent[index + 1].title
          }
        })
        setIsTryForFreeDisplayed(false)
      }, 3000)
    )
  }

  useEffect(() => {
    videoRef?.current?.load()
    setPosterClicked(false)
  }, [activeSport])

  return (
    <Layout
      header={
        <Header
          num={1}
          translations={_}
          headerFullYOffset={1000}
          mobileHeaderFullYOffset={40}
          fixed
          languageCode={languageCode}
        />
      }
      translations={_}
      languageCode={languageCode}
    >
      <div className={styles.watchNow}>
        <h3>{activeSport}</h3>
        <Link
          className={styles.backLink}
          to={`/${languageCode === "en" ? "" : languageCode}`}
          onMouseEnter={() => setBackIconUrl(chevronIcon)}
          onMouseLeave={() => setBackIconUrl(chevronIconBlack)}
        >
          <img src={backIconUrl} />
          {_.back}
        </Link>
        {!isTryForFreeDisplayed && (
          <video
            controls={true}
            autoPlay={true}
            muted={true}
            ref={videoRef}
            onEnded={() => displayTryForFree()}
            preload="auto"
            playsInline
            poster={posterImg}
            className={!posterClicked && styles.hideOnMobile}
            onPlay={() => setPosterClicked(true)}
          >
            <source
              src={sportContent.find((s) => s.title === activeSport)?.video}
              type="video/mp4"
            />
          </video>
        )}
        {!isTryForFreeDisplayed && !posterClicked && (
          <img
            src={posterImg}
            onClick={() => {
              setPosterClicked(true)
              videoRef.current.play()
            }}
          />
        )}
        {isTryForFreeDisplayed && (
          <div class={styles.tryForFree}>
            <Button
              classStyle={styles.button}
              onClick={() => {
                clearTimeout(timeoutId)
                openModal()
                setWasModalOpened(true)
              }}
            >
              {_.try_xps.try_xps_for_free}
            </Button>
            <p>{_.try_xps.no_credit_card}</p>
            {!wasModalOpened && <ProgressBar />}
          </div>
        )}
      </div>
      <div className={styles.sportsWrapper}>
        <div
          className={styles.arrowButton}
          onClick={() => sportsRef.current.scrollBy(-200, 0)}
        >
          <img src={chevronIcon} />
        </div>
        <div className={[styles.fade, styles.left].join(" ")} />
        <div className={styles.sportsList} ref={sportsRef}>
          <div className={styles.space} />
          {sportContent.map((s) => (
            <IconButton
              key={s.title}
              icon={s.icon?.publicURL}
              title={s.title}
              className={
                s.title === activeSport
                  ? `${styles.active} ${styles.sportButton}`
                  : styles.sportButton
              }
              onClick={() => {
                if (s.title !== activeSport) {
                  navigate(
                    `${
                      languageCode === "en" ? "" : `/${languageCode}`
                    }/watchNow/${s.icon?.name ?? ""}`
                  )
                  setIsTryForFreeDisplayed(false)
                  setActiveSport(s.title)
                  setWasModalOpened(false)
                }
              }}
            />
          ))}
          <div className={styles.space} />
        </div>
        <div className={[styles.fade, styles.right].join(" ")} />
        <div
          className={styles.arrowButton}
          onClick={() => sportsRef.current.scrollBy(200, 0)}
        >
          <img src={chevronIcon} />
        </div>
      </div>
      <GetStarted translations={_} />
      <Header
        num={2}
        translations={_}
        headerFullYOffset={1000}
        boxShadow
        languageCode={languageCode}
        className={globalStyles.hideOnMobile}
      />
      <Footer translations={_} languageCode={languageCode} />
    </Layout>
  )
}

export default WatchNow

export const query = graphql`
  query WatchNow($langFile: String) {
    site {
      siteMetadata {
        ip_stack_key
      }
    }
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        ...TryXpsModalTranslations
        time_to_get_started
        enter_your_email
        start_free_trial
        back
        watch_now {
          all_sports
          xps_app
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
        customers {
          all_sports
        }
      }
    }
  }
`
