import React from "react"
import PropTypes from "prop-types"
import styles from "./iconButton.module.scss"

const IconButton = ({
  icon,
  title,
  onClick,
  style,
  className,
  width,
  height,
  id
}) => {
  return (
    <button
      id={id}
      style={style}
      className={[styles.col2, styles.iconButton, className].join(" ")}
      onClick={onClick}
    >
      <img
        id={id}
        style={{
          height: height ? height : 25,
          width: width ? width : 25,
          objectFit: "contain",
        }}
        src={icon}
        className={styles.icon}
        loading="lazy"
      />
      <div id={id} className={styles.title}>
        {title}
      </div>
    </button>
  )
}

IconButton.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
}

export default IconButton
